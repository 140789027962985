import React, { useState } from "react"
import Fade from 'react-reveal/Fade';

import ReadMore from "../components/common/ReadMore"
import ReadLess from "../components/common/ReadLess"
import CreateGallery from "../components/common/CreateGallery"

function Gallery({ data, options }) {
  const [open, setOpen] = useState(false);

  return (
    <section id="gallery">
      <div className="container-fluid mt-5 p-0">
        <Fade up>
          <h2 className="text-center">{data.title}</h2>
          <hr className="divider mb-5" />
        </Fade>
        <div className="row no-gutters p-0">
          {open ? <><CreateGallery image={data.gallery} /></> : <><CreateGallery image={data.gallery.slice(0, 4)} /></>}
          <div className="col-12 text-center mt-3 mb-5">
            <button className="more"
              onClick={() => setOpen(!open)}
              aria-controls="example-collapse-text" // TODO: cambiar Example collapse text?
              aria-expanded={open}>
              {open ? (<ReadLess data={options.viewless} />) : (<ReadMore data={options.viewmore} />)}
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Gallery