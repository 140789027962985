import React from "react"
import DownArrow from "../../images/assets/down-arrow-black.svg"

function ReadMore({data}) {

    return (
        <>
        <div className="more-text">{data}</div>
        <img src={DownArrow} className="icon" alt="chevron"/>
        </>
       )
}

ReadMore.defaultProps = {
    data: "Read more"
}

export default ReadMore