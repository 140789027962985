import React from "react"
import Fade from 'react-reveal/Fade'
import Img from "gatsby-image"

function AboutPortfolio(props) {
    
    let yourArray = props.data.list;
    let halfwayThrough = Math.floor(yourArray.length / 2)

    let arrayFirstHalf = yourArray.slice(0, halfwayThrough);
    let arraySecondHalf = yourArray.slice(halfwayThrough, yourArray.length);

    function createList(props) {
        
        const listItems = props.map((number) =>
            <li key={number.text} ><i className="fa fa-bolt"></i>{number.text}</li>
        );
        return (
            <ul>{listItems}</ul>
        );
    }
    
    return (<div className="container-fluid">
        <div className="row justify-content-center">
            <div className="col-md-6 mb-5 px-0 text-center">
                <Fade left>
                    <div className="bio" style={{ background: 'linear-gradient(to right bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.1)' }} >
                        <Img className="bio-profile" fluid={props.data.fluid.childImageSharp.fluid} alt="profile" style={{ backgroundPosition: 'top center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundAttachment: 'fixed' }} />
                    </div>
                </Fade>
            </div>
            <div className="col-lg-6 px-5">
                <Fade right>
                    <h2 className="mt-5  text-center">{props.data.title}</h2>
                    <hr className="divider mb-5" />
                    {props.data.description.slice(0,3).map((key, index) => <p key={index} className="mb-4"> {key.p} </p>)}
                    <div className="row mt-3">
                        <div className="col-md-6">
                            {createList(arrayFirstHalf)}
                        </div>
                        <div className="col-md-6">
                            {createList(arraySecondHalf)}
                        </div>
                    </div>
                </Fade>
            </div>
        </div>
    </div>
    )
}

export default AboutPortfolio
