import React from "react"
import Fade from 'react-reveal/Fade';



function Testimonial({data}) {

    function getTestimonials() {
        const listItems = data.list.map((key) =>
        <Fade key={key.name} left>
        <li key={key.name}>
        <div className="row justify-content-center">
          <div className="col-md-2 col-sm-12 mb-5">
            <div className="image">
              <img src={key.image.childImageSharp.fluid.src} className="mx-auto img-fluid" alt="testimonio" />
            </div>
          </div>
          <div className="col-md-8 col-sm-12 testimonial">
            <h3 className="testimonial-name">{key.name}</h3>
            <div className="testimonial-position"><i className="fab fa-linkedin-in"></i> | {key.position}</div>
            <div className="testimonial-testimonio">{key.testimonio}</div>  
          </div>
        </div>
      </li>
      </Fade>
        );
        return (
            
                <ul>{listItems}</ul>
           
          
        );
      }
    
    return (
        <section id="testimonial">
          <div className="container my-5">
            <div className="row justify-content-center">
              <div className="col-lg-12 text-center">
                <Fade><h2>{data.title}</h2>
                  <hr className="divider mb-5" /></Fade>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-12">
                {getTestimonials()}
              </div>
            </div>
          </div>
        </section>
       )
}

export default Testimonial