import React from "react"

function HeroPortfolio(props) {

  return (<>
             <div className="col-md-12 ">
              <h1 className="text-uppercase text-white portfolio text-center ">{props.data.title}</h1>
              <p className="text-white font-weight-light text-center">{props.data.subtitle}</p>
              <hr align="center" className="divider mb-3" />
            </div>
            <div className="col-lg-8 align-self-baseline mx-auto">
                          </div>
        </>
  )
}

export default HeroPortfolio
