import React from "react"
import DownArrow from "../../images/assets/down-arrow-black.svg"

function ReadLess({data}) {

    return (
        <>
        <div className="more-text">{data}</div>
        <img src={DownArrow} className="readmore icon" alt="chevron" style={{transform: "rotate(180deg)"}}/>
        </>
       )
}

ReadLess.defaultProps = {
    data: "Read less"
}

export default ReadLess