import React from "react"
import { graphql } from "gatsby"
import { CookiesProvider } from 'react-cookie';


import Layout from "../components/layout"
import SEO from "../components/seo"
import Scroller from "../components/scroller"
import Footer from "../components/footer"
import Hero from "../components/hero"
import AboutUs from "../components/about"
import Portfolio from "../components/portfolio"
import Gallery from "../components/gallery"
import Media from "../components/media"
import Testimonial from "../components/testimonial"
import Process from "../components/process"
import Instagram from "../components/instagram"
import CookiesConsent from "../components/cookies"

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    Scroller.handleAnchorScroll = Scroller.handleAnchorScroll.bind(this)

  }

  render() {
    
    const data = this.props.data.music.frontmatter
    return (
      <Layout nav={data.navegation} >
        <SEO page={'inicio'} title={data.title} metas={data.metas} siteurl={data.siteurl} />
        {data.onepage_hero ? <Hero theme={data.theme} data={data.hero}/> : null }
        {data.onepage_about ? <AboutUs theme={'music'} data={data.about} options={data.options}/> : null}
        {data.onepage_portfolio ? <Portfolio data={data.portfolio} anchor={'portfolio'} options={data.options}/> : null}
        {data.onepage_gallery ? <Gallery data={data.gallery} options={data.options}/> : null}
        {data.onepage_instagram ? <Instagram data={data.instagram} options={data.options} /> : null}
        {data.onepage_media ? <Media data={data.media}/> : null }
        {data.onepage_projects ? <Portfolio data={data.projects} anchor={'project'} options={data.options}/> : null}
        {data.onepage_testimonials ? <Testimonial data={data.testimonio} anchor={'testimonial'}/> : null}
        {data.onepage_process ? <Process data={data.process}/> : null}
        {data.onepage_contact ? <Footer data={data.contact} /> : null}
        
        <CookiesProvider>
          <CookiesConsent data={data.cookies} />
        </CookiesProvider>
      </Layout>
    )
  }
}


export const imageData = graphql`
  query($slug: String!,$onepage_hero: Boolean!,$onepage_about: Boolean!,$onepage_portfolio: Boolean!,$onepage_instagram: Boolean!,$onepage_gallery: Boolean!,$onepage_projects: Boolean!,$onepage_media: Boolean!,$onepage_testimonials: Boolean!,$onepage_process: Boolean!,$onepage_contact: Boolean!) {
    music: markdownRemark(fields: { slug: { eq: $slug } }) {
        frontmatter {
            theme
            title
            onepage_hero 
            onepage_about 
            onepage_portfolio 
            onepage_instagram 
            onepage_gallery 
            onepage_projects 
            onepage_media 
            onepage_testimonials 
            onepage_process 
            onepage_contact 
            siteurl
            ...metasFields
            ...navFields
            ...heroFields @include (if: $onepage_hero)
            ...aboutFields @include (if: $onepage_about)
            ...instagramFields @include (if: $onepage_instagram)
            ...galleryFields @include (if: $onepage_gallery)
            ...testimonialFields @include (if: $onepage_testimonials)
            ...portfolioFields @include (if: $onepage_portfolio)
            ...projectsFields @include (if: $onepage_projects)
            ...contactFields @include (if: $onepage_contact)
            ...mediaFields @include (if: $onepage_media)
            ...processFields @include (if: $onepage_process)    
            ...cookiesFields 
            ...optionFields
      }
    }
  }  
`
