import React  from "react"
import AboutPortfolio from "../components/about/about-portfolio"
import AboutMusic from "../components/about/about-music"


function AboutUs({data, theme, options}) {
// TODO: Añadir select case
    return (
        <section id="about"> 
          {theme === 'music' ? <AboutMusic data={data} options={options}/> : <AboutPortfolio data={data} options={options}/>} 
        </section>
       )
}

export default AboutUs